<template>
  <b-card no-body class="m-2">
    <b-card-header class="pb-50">
      <h5 class="text-capitalize">
        {{ $tc("actions.filter", 1) }}
      </h5>
      <b-button
        variant="secondary"
        class="float-right text-capitalize"
        @click="$emit('update-account-data')"
        >{{ $t("actions.update") + " " + $tc("account.title", 2) }}
      </b-button>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col cols="6" md="6" class="mb-md-0 mb-2 text-capitalize">
          <label> {{ $tc("master_account.title", 1) }}</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="masterAccountFilter"
            :options="masterAccountOptions"
            class="w-100"
            :reduce="(val) => val.value"
            multiple
            @input="(val) => $emit('update:masterAccountFilter', val)"
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BButton,
} from "bootstrap-vue";
import vSelect from "vue-select";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
    vSelect,
  },
  props: {
    masterAccountOptions: {
      type: Array,
      default: null,
    },
    masterAccountFilter: {
      type: Array,
      default: null,
    },
  },
};
</script>
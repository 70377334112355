import { ref, computed, watch } from '@vue/composition-api'
// import { title } from '@core/utils/filter'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment-timezone'
import store from '@/store'
import router from '@/router'
import { debounce } from 'lodash'

export default function useList() {
  const refListTable = ref(null)

  const toast = useToast()

  store.dispatch('bd_master_account/getAll')

  const masterAccountOptions = computed(() => store.getters['bd_master_account/getSelectedItems'])
  const masterAccountFilter = ref(null)

  if (router.currentRoute.params.id_master_account)
    masterAccountFilter.value = [parseInt(router.currentRoute.params.id_master_account)]

  const tableColumns = [
    { label: 'actions.name', key: 'actions' },
    { label: 'account._name', key: 'name', sortable: true },
    { label: 'master_account.title', key: 'master_account', sortable: true },
    { label: 'account.bridge_number', key: 'bridges_count', sortable: true },
    { label: 'account.online_cameras', key: 'camera_online_count', sortable: true },
    { label: 'account.number_of_cameras', key: 'camera_count', sortable: true },
    { label: 'account.number_of_users', key: 'user_count', sortable: true },
    { label: 'account.active', key: 'is_active', sortable: true },
  ]

  const perPage = ref(10)
  const totalList = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalList.value,
    }
  })

  const fetchList = (ctx, callback) => {
    store.dispatch('bd_account/getAll', {
      q: searchQuery.value,
      max: perPage.value,
      'page[n]': currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      id_master_account: masterAccountFilter.value,
    })
    .then(response => {
      callback(response.data)
      totalList.value = response.meta.total
    })
    .catch(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error obteniendo la información',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })
  }

  const formatDateAssigned = value => {
    const formattedDate = moment(value).format('DD/MM/YYYY HH:mm:ss')
    return formattedDate
  }

  const refetchData = () => {
    refListTable.value.refresh()
  }

  watch([masterAccountFilter, currentPage, perPage, ], () => {
    refetchData()
  })

  watch([ searchQuery ], debounce(() => {
    refetchData()
  }, 500))

  return {
    tableColumns,
    perPage,
    currentPage,
    totalList,
    fetchList,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refListTable,
    formatDateAssigned,

    masterAccountOptions,
    masterAccountFilter,

    refetchData,
  }
}
